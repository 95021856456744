export const tagTypes = {
  user: 'user',
  user_details: 'user_details',
  user_tenant_details: 'user_tenant_details',
  user_tenant_list: 'user_tenant_list',
  domains_list: 'domains_list',
  configs_by_group: 'configs_by_group',
  draft_configs: 'draft_configs',
  live_configs: 'live_configs',
  revision_configs: 'revision_configs',
  configs_by_groups_list: 'configs_by_groups_list',
  tenant_languages: 'tenant_languages',
  all_languages: 'all_languages',
  translations: 'translations',
  dynamic_pages: 'dynamic_pages',
  currencies: 'currencies',
}

export const tagTypesList = [
  tagTypes.user_details,
  tagTypes.user_tenant_details,
  tagTypes.user_tenant_list,
  tagTypes.configs_by_group,
  tagTypes.configs_by_groups_list,
  tagTypes.draft_configs,
  tagTypes.live_configs,
  tagTypes.domains_list,
  tagTypes.tenant_languages,
  tagTypes.all_languages,
  tagTypes.translations,
  tagTypes.dynamic_pages,
  tagTypes.currencies,
]
