import axios from 'axios'
import { base_marketplace_url, base_url, site_url } from 'constant/url'
import { to } from 'await-to-js'
import tradly from 'tradly'
import { api_call } from '@/WebHandler/api_call'
import {
  templateDecryptDataWithAES,
  templateEncryptDataWithAES,
  getSelectedTenantDetails,
} from 'constant/functions'
import { proxy_api_call } from '@/WebHandler/proxy_api_call'
import { export_config_keys } from 'data/Config_keys'
import { apiSlice } from '@/redux/api/apiSlice'
import { tagTypes } from '@/redux/api/TagTypes'
import { configs_domain_keys_for_duplicate } from 'constant/ConfigsKey'
var slugify = require('slugify')

// getUserDetails
export const getUserDetails = async (auth_key) => {
  const url = `${base_url}/skua/users/detail`
  var header = {
    'Content-Type': 'application/json',
    'x-auth-key': auth_key,
  }

  const [err, response] = await to(
    axios({
      url: url,
      method: 'GET',
      responseType: 'json',
      headers: header,
    })
  )
  if (err?.response?.data) {
    return err
  } else {
    return response?.data?.data
  }
}

// getBusinessProfileDetails
export const getBusinessProfileDetails = async (auth_key) => {
  const url = `${base_url}/skua/tenants/users_tenants`
  var header = {
    'Content-Type': 'application/json',
    'x-auth-key': auth_key,
  }
  const [err, response] = await to(
    axios({
      url: url,
      method: 'GET',
      responseType: 'json',
      headers: header,
    })
  )
  if (err) {
    //
  } else {
    return response?.data?.data
  }
}

// subscription plan
export const getSubscribedPlan = async (auth_key, router) => {
  const url = `${base_url}/skua/subscriptions/plans`
  var header = {
    'Content-Type': 'application/json',
    'x-auth-key': auth_key,
  }

  const [err, response] = await to(
    axios({
      url: url,
      method: 'GET',
      responseType: 'json',
      headers: header,
    })
  )
  if (err) {
    router.push(`/signin?to=${site_url}${router.asPath}`)
  } else {
    return response?.data?.data
  }
}

// changePlan
export const changePlan = async (auth_key, plan_name, isTrial, isAnnual) => {
  const url = `${base_url}/skua/subscriptions`
  var header = {
    'Content-Type': 'application/json',
    'x-auth-key': auth_key,
  }

  let data = { plan_name: plan_name }

  if (isTrial) {
    data.trial = true
  }
  if (isAnnual) {
    data.recurring_type = 'yearly'
  } else {
    data.recurring_type = 'monthly'
  }

  const [err, response] = await to(
    axios({
      url: url,
      method: 'PATCH',
      responseType: 'json',
      headers: header,
      data: data,
    })
  )
  if (err) {
    //   Here need some update
    return err.response.data
  } else {
    return response.data
  }
}

// image_url
export const image_url = (files, full_files, auth_key) => {
  return tradly.app
    .generateS3ImageURL({
      authKey: auth_key,
      data: { files: [files] },
    })
    .then((res) => {
      if (!res.error) {
        const path = res.data.result[0].signedUrl
        const ImagePath = res.data.result[0].fileUri
        return fetch(path, {
          method: 'PUT',
          headers: {
            ContentType: full_files.type,
          },
          body: full_files,
        }).then((res) => {
          if (res.status == 200) {
            return ImagePath
          }
        })
      }
    })
}

// Upload Images
export const upload_images = async (all_files, auth_key) => {
  let all_files_uri = []
  let upload_files = []
  let upload_full_files = []
  for (let i = 0; i < all_files.length; i++) {
    const element = all_files[i]

    // Set in state
    if (element.full_file === null) {
      all_files_uri.push(element.path)
    } else {
      let file_data = {
        name: element.name.replace(/\s/g, '-'),
        type: element.type,
      }

      upload_files.push(file_data)
      upload_full_files.push(element)
    }

    // Upload images checking
    if (all_files.length === i + 1 && upload_files.length > 0) {
      try {
        const response = await tradly.app.generateS3ImageURL({
          authKey: auth_key,
          data: { files: upload_files },
        })

        const responseFiles = response.data.result
        for (let index = 0; index < responseFiles.length; index++) {
          const path = responseFiles[index].signedUrl
          const fileURI = responseFiles[index].fileUri

          try {
            const res = await fetch(path, {
              method: 'PUT',
              headers: {
                ContentType: upload_files[index].type,
              },
              body: upload_full_files[index],
            })

            if (res.ok) {
              all_files_uri.push(fileURI)
            }
          } catch (error) {
            // console.log('====================================')
            // console.log(error)
            // console.log('====================================')
          }
        }
      } catch (error) {
        // console.log('====================================')
        // console.log(error)
        // console.log('====================================')
      }
    }

    // return all images url
    if (all_files_uri.length == all_files.length) {
      return all_files_uri
    }
  }
}

// Get configs

export const get_configs = async (auth_key, key_group, domain_id) => {
  const res = await axios({
    url: `/api/configs`,
    method: 'GET',
    params: {
      key_group,
      domain_id,
    },
  })
  if (!res.error) {
    const configs_resp = JSON.parse(templateDecryptDataWithAES(res?.data))
    return configs_resp.configs
  }
}
// Get configs by list

export const get_configs_list = async (key_group, domain_id) => {
  const res = await axios({
    url: `/api/configs/list`,
    method: 'GET',
    params: {
      key_group,
      domain_id,
    },
  })
  if (!res.error) {
    const configs_resp = JSON.parse(templateDecryptDataWithAES(res?.data))
    return configs_resp.configs
  }
}

// Get user tenants
export const get_user_tenants = (auth_key) => {
  const url = `${base_url}/skua/tenants/users_tenants`
  var header = {
    'Content-Type': 'application/json',
    'x-auth-key': auth_key,
  }
  return axios({
    url: url,
    method: 'GET',
    responseType: 'json',
    headers: header,
  }).then((res) => {
    if (!res.error) {
      return getSelectedTenantDetails(res.data.data.tenants)
    }
  })
}

// Save Onboarding parts editor data
export const save_onboarding_data = async (
  screen1Path,
  screen1_files,
  screen1_text,
  screen2Path,
  screen2_files,
  screen2_text,
  screen3Path,
  screen3_files,
  screen3_text,
  data,
  setIsLoading,
  auth_key,
  draft_styles,
  submit_type,
  router,
  isDraft,
  setISDraft,
  success,
  dispatch
) => {
  setIsLoading(true)

  var screen1 = screen1_files
    ? await image_url(
        { name: screen1_files.image_file.name, type: screen1_files.image_file.type },
        screen1_files.image_file,
        auth_key
      )
    : screen1Path?.path
    ? screen1Path?.path
    : ''
  var screen2 = screen2_files
    ? await image_url(
        { name: screen2_files.image_file.name, type: screen2_files.image_file.type },
        screen2_files.image_file,
        auth_key
      )
    : screen2Path?.path
    ? screen2Path?.path
    : ''
  var screen3 = screen3_files
    ? await image_url(
        { name: screen3_files.image_file.name, type: screen3_files.image_file.type },
        screen3_files.image_file,
        auth_key
      )
    : screen3Path?.path
    ? screen3Path?.path
    : ''

  let intro_screens = {
    intro_screens: [
      {
        image: screen1,
        text: screen1_text,
      },
      {
        image: screen2,
        text: screen2_text,
      },
      {
        image: screen3,
        text: screen3_text,
      },
    ],
  }

  const url = `/api/configs`
  var header = {
    'Content-Type': 'application/json',
    'x-auth-key': auth_key,
  }

  let elements = {
    ...draft_styles,
    onboarding: { ...draft_styles?.onboarding, ...data, ...intro_screens },
  }

  proxy_api_call({
    method: 'POST',
    path: url,
    data: templateEncryptDataWithAES(
      JSON.stringify({
        configs: [{ key: 'styles', key_group: 'draft_styles', secured: false, value: elements }],
      })
    ),
    onSuccess: (resp) => {
      if (submit_type === 'publish') {
        const array_configs = Object.entries({ ...data, ...intro_screens })
        let configs = []
        let delete_keys = ''
        for (let i = 0; i < array_configs.length; i++) {
          const element = array_configs[i]
          if (element[1].toString().length > 0) {
            configs.push({
              key: element[0],
              key_group: 'onboarding',
              secured: false,
              value: element[1],
            })
          } else {
            delete_keys += element[0] + ','
          }
        }

        //
        proxy_api_call({
          method: 'POST',
          path: url,
          data: templateEncryptDataWithAES(
            JSON.stringify({
              configs: [...configs],
            })
          ),
          onSuccess: (resp) => {
            if (delete_keys.length > 0) {
              api_call({
                method: 'DELETE',
                path: `/v1/configs?key=${delete_keys}`,
                data: {},
                onSuccess: (response) => {
                  dispatch(
                    apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs])
                  )

                  setIsLoading(false)
                  success()
                },
                onError: (error) => {
                  dispatch(
                    apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs])
                  )

                  setIsLoading(false)
                  success()
                },
              })
            } else {
              dispatch(
                apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs])
              )

              setIsLoading(false)
              success()
            }
          },
          onError: (error) => {
            if (delete_keys.length > 0) {
              api_call({
                method: 'DELETE',
                path: `/v1/configs?key=${delete_keys}`,
                data: {},
                onSuccess: (response) => {
                  dispatch(
                    apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs])
                  )

                  setIsLoading(false)
                  success()
                },
                onError: (error) => {
                  dispatch(
                    apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs])
                  )

                  setIsLoading(false)
                  success()
                },
              })
            } else {
              setIsLoading(false)
            }
          },
        })
      } else {
        dispatch(
          apiSlice.util.invalidateTags([
            tagTypes.draft_configs,
            tagTypes.live_configs,
            tagTypes.revision_configs,
          ])
        )

        setIsLoading(false)
        setISDraft(true)
        let domain = new URL(document.getElementById('preview_iframe')?.src)
        domain.search.includes('&draft=true')
          ? (document.getElementById('preview_iframe').src =
              domain.origin + domain.pathname + domain.search)
          : (document.getElementById('preview_iframe').src =
              domain.origin + domain.pathname + domain.search + '&draft=true')
      }
    },
    onError: (err) => {
      setIsLoading(false)
    },
  })
}

// Save Editor   data
export const save_editor_data = async (
  config_key_group,
  images,
  form_data,
  setIsLoading,
  auth_key,
  draft_styles,
  submit_type,
  router,
  isDraft,
  setISDraft,
  success,
  domain_id,
  dispatch
) => {
  setIsLoading(true)
  let all_images = {}
  if (images?.length > 0) {
    for (let i = 0; i < images?.length; i++) {
      let image = images[i]
      const _image_url = image.file
        ? await image_url({ name: image.file.name, type: image.file.type }, image.file, auth_key)
        : image?.path
        ? image.path
        : ''
      all_images[image.title] = _image_url
    }
  }
  let elements = {
    ...draft_styles,
    [config_key_group]: { ...draft_styles?.[config_key_group], ...form_data, ...all_images },
  }

  let draft_config_data = {
    configs: [{ key: 'styles', key_group: 'draft_styles', secured: false, value: elements }],
  }
  if (domain_id) {
    draft_config_data.domain_id = domain_id
  }
  proxy_api_call({
    method: 'POST',
    path: `/api/configs`,
    data: templateEncryptDataWithAES(JSON.stringify(draft_config_data)),
    onSuccess: (resp) => {
      if (submit_type === 'publish') {
        const array_configs = Object.entries({ ...form_data, ...all_images })
        let configs = []
        let delete_keys = ''
        for (let i = 0; i < array_configs.length; i++) {
          const element = array_configs[i]
          if (element[1].toString().length > 0) {
            configs.push({
              key: element[0],
              key_group: config_key_group,
              secured: false,
              value: element[1],
            })
          } else {
            delete_keys += element[0] + ','
          }
        }
        //
        let config_data = {
          configs: [...configs],
        }
        if (domain_id) {
          config_data.domain_id = domain_id
        }
        proxy_api_call({
          method: 'POST',
          path: `/api/configs`,
          data: templateEncryptDataWithAES(JSON.stringify(config_data)),
          onSuccess: (resp) => {
            if (delete_keys.length > 0) {
              api_call({
                method: 'DELETE',
                path: `/v1/configs?key=${delete_keys}`,
                data: {},
                onSuccess: (response) => {
                  dispatch(
                    apiSlice.util.invalidateTags([
                      tagTypes.draft_configs,
                      tagTypes.live_configs,
                      tagTypes.revision_configs,
                    ])
                  )

                  setIsLoading(false)
                  success()
                },
                onError: (error) => {
                  dispatch(
                    apiSlice.util.invalidateTags([
                      tagTypes.draft_configs,
                      tagTypes.live_configs,
                      tagTypes.revision_configs,
                    ])
                  )
                  setIsLoading(false)
                  success()
                },
              })
            } else {
              dispatch(
                apiSlice.util.invalidateTags([
                  tagTypes.draft_configs,
                  tagTypes.live_configs,
                  tagTypes.revision_configs,
                ])
              )

              setIsLoading(false)
              success()
            }
          },
          onError: (error) => {
            if (delete_keys.length > 0) {
              api_call({
                method: 'DELETE',
                path: `/v1/configs?key=${delete_keys}`,
                data: {},
                onSuccess: (response) => {
                  setIsLoading(false)
                  success()
                },
                onError: (error) => {
                  setIsLoading(false)
                  success()
                },
              })
            } else {
              setIsLoading(false)
            }
          },
        })
      } else {
        dispatch(apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs]))

        let domain = new URL(document.getElementById('preview_iframe')?.src)
        domain.search.includes('&draft=true')
          ? (document.getElementById('preview_iframe').src =
              domain.origin + domain.pathname + domain.search)
          : (document.getElementById('preview_iframe').src =
              domain.origin + domain.pathname + domain.search + '&draft=true')
        setIsLoading(false)
        setISDraft(true)
      }
    },
    onError: (err) => {
      setIsLoading(false)
    },
  })
}

// Publish (draft to production)

export const publish = async (
  auth_key,
  draft_styles,
  tenant_configs,
  isDraft,
  setIsLoading,
  Success,
  domain_id,
  dispatch
) => {
  setIsLoading(true)

  // const array_configs = isDraft ? Object.entries(draft_styles) : Object.entries(tenant_configs)
  const array_configs = draft_styles
    ? Object?.keys(draft_styles)?.length > 0
      ? Object.entries(draft_styles)
      : Object.entries(tenant_configs)
    : Object.entries(tenant_configs)

  const configs = array_configs.flatMap((element) => {
    const config_keys = Object.entries(element[1])

    return config_keys
      .filter((key) => key[1].toString().length > 0)
      .map((key) => ({
        key: key[0],
        key_group: element[0],
        secured: false,
        value: key[1],
      }))
  })

  // this need to update latter
  let delete_keys = ''

  const url = `/api/configs`
  var header = {
    'Content-Type': 'application/json',
    'x-auth-key': auth_key,
  }
  //
  let config_data = {
    configs: [...configs],
  }

  if (domain_id) {
    config_data.domain_id = domain_id
  }

  proxy_api_call({
    method: 'POST',
    path: url,
    data: templateEncryptDataWithAES(JSON.stringify(config_data)),

    onSuccess: (res) => {
      if (delete_keys.length > 0) {
        api_call({
          method: 'DELETE',
          path: `v1/configs?keys=${delete_keys}`,
          data: {},
          onSuccess: (res) => {
            dispatch(apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs]))

            setIsLoading(false)
            Success()
          },
          onError: (err) => {
            dispatch(apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs]))

            setIsLoading(false)
            Success()
          },
        })
      } else {
        dispatch(apiSlice.util.invalidateTags([tagTypes.draft_configs, tagTypes.live_configs]))

        setIsLoading(false)
        Success()
      }
    },
    onError: (err) => {
      setIsLoading(false)
    },
  })
}

// Duplicate Configs
export const duplicate_configs = async (tenant_configs, domain_id) => {
  const decrypted_data = JSON.parse(templateDecryptDataWithAES(tenant_configs))

  const array_configs = Object.entries(decrypted_data)

  let configs = array_configs.map(([group_name, items]) => {
    const config_keys = Object.entries(items)

    const configs_items = config_keys.map(([key_name, values]) => {
      return {
        key: key_name,
        key_group: group_name,
        secured: false,
        value: values,
      }
    })

    return configs_items
  })

  let deleted_configs_keys = array_configs.map(([group_name, items]) => {
    const config_keys = Object.entries(items)

    let configs_item
    if (Object?.keys(config_keys).length) {
      configs_item = config_keys.filter(([key_name, values]) => values?.toString()?.length == 0)
    } else {
      configs_item = export_config_keys(group_name)
    }

    return configs_item
  })

  const flattenedArray = configs.flat()
  const flattenedDeletedItemsArray = deleted_configs_keys.flat()

  // Remove empty inner arrays from the flattened array.
  const filteredArray = flattenedArray.filter((element) => element !== [])
  const filteredDeletedItemsArray = flattenedDeletedItemsArray.filter((element) => element !== [])

  // function for delete keys and than return
  try {
    let delete_res
    if (filteredDeletedItemsArray.length > 0) {
      delete_res = await axios({
        url: `/api/configs`,
        method: 'DELETE',
        params: {
          keys: filteredDeletedItemsArray.join(','),
        },
      })
    }
    return axios({
      url: `/api/configs`,
      method: 'POST',
      data: {
        data: templateEncryptDataWithAES(
          JSON.stringify({
            configs: filteredArray,
            domain_id,
          })
        ),
      },
    })
  } catch (error) {
    return axios({
      url: `/api/configs`,
      method: 'POST',
      data: {
        data: templateEncryptDataWithAES(
          JSON.stringify({
            configs: filteredArray,
            domain_id,
          })
        ),
      },
    })
  }
}

// fetch configs by domain

export const fetch_configs_by_domain = async ({
  domain,
  key_group,
  domain_id,
  is_duplicate_tenant_configs,
}) => {
  try {
    const domain_result = await axios({
      url: `${base_url}/skua/tenants/pk_by_domain?domain=${domain}&env=production`,
      method: 'GET',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const pk_key = domain_result.data.data.key
    const duplicate_temp_domain_id = domain_result.data.data.domain.id

    // tenant level configs

    const tenant_configs_result = await axios({
      url: `/api/configs/tenant`,
      method: 'GET',
      params: { pk_key, key_group },
    })

    const tenant_decrypted_data = JSON.parse(templateDecryptDataWithAES(tenant_configs_result.data))

    const tenant_array_configs = Object.entries(tenant_decrypted_data.configs)

    let tenant_configs = tenant_array_configs.map(([group_name, items]) => {
      const config_keys = Object.entries(items)

      // condition after remove empty values
      const configs_items_after_remove_empty = config_keys.filter(
        ([key_name, values]) => values?.toString()?.length !== 0
      )
      //
      const configs_items = configs_items_after_remove_empty.map(([key_name, values]) => {
        if (values?.toString()?.length !== 0) {
          return {
            key: key_name,
            key_group: group_name,
            secured: false,
            value: values,
          }
        }
      })

      return configs_items
    })
    const tenantConfigsFlattenedArray = tenant_configs.flat()
    const tenantFilteredArray = tenantConfigsFlattenedArray.filter((element) => element !== [])

    // set tenant configuration
    await axios({
      url: `/api/configs`,
      method: 'POST',
      data: {
        data: templateEncryptDataWithAES(
          JSON.stringify({
            configs: tenantFilteredArray,
            operation: 'duplicate_template',
          })
        ),
      },
    })

    // domain level confids
    const domain_configs_result = await axios({
      url: `/api/configs/by_keys`,
      method: 'GET',
      params: {
        pk_key,
        key_group: configs_domain_keys_for_duplicate,
        domain_id: duplicate_temp_domain_id,
      },
    })
    const domain_decrypted_data = JSON.parse(templateDecryptDataWithAES(domain_configs_result.data))

    const domain_array_configs = Object.entries(domain_decrypted_data.configs)

    let domain_configs = domain_array_configs.map(([group_name, items]) => {
      const config_keys = Object.entries(items)

      // condition after remove empty values
      const configs_items_after_remove_empty = config_keys.filter(
        ([key_name, values]) => values?.toString()?.length !== 0
      )
      //
      const configs_items = configs_items_after_remove_empty.map(([key_name, values]) => {
        if (values?.toString()?.length !== 0) {
          return {
            key: key_name,
            key_group: group_name,
            secured: false,
            value: values,
          }
        }
      })

      return configs_items
    })
    const domainConfigsFlattenedArray = domain_configs.flat()

    // Remove empty inner arrays from the flattened array.
    const domainFilteredArray = domainConfigsFlattenedArray.filter((element) => element !== [])

    //
    return axios({
      url: `/api/configs`,
      method: 'POST',
      data: {
        data: templateEncryptDataWithAES(
          JSON.stringify({
            configs: domainFilteredArray,
            domain_id,
          })
        ),
      },
    })
  } catch (error) {
    // console.log('====================================')
    // console.log({ error })
    // console.log('====================================')
  }
}

//  convert draft by prod data
export const convertDraftByProdData = async ({ key_group, domain_id }) => {
  const domain_configs_result = await axios({
    url: `/api/configs/list`,
    method: 'GET',
    params: { key_group, domain_id },
  })
  const domain_decrypted_data = JSON.parse(templateDecryptDataWithAES(domain_configs_result.data))

  // Remove empty inner arrays from the flattened array.
  const domainUpdatedArray = [
    {
      key: 'styles',
      key_group: 'draft_styles',
      secured: false,
      value: domain_decrypted_data?.configs,
    },
  ]
  //
  return axios({
    url: `/api/configs`,
    method: 'POST',
    data: {
      data: templateEncryptDataWithAES(
        JSON.stringify({
          configs: domainUpdatedArray,
          domain_id,
        })
      ),
    },
  })
}
